import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";

import HeroText from "../components/heroText";
import { StaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import BackgroundImage from "gatsby-background-image";
const Index = () => {
  return (
    <Layout>
      <SEO
        title="Ομαδική Ψυχοθεραπεία"
        keywords={[`ομαδική`, `ομαδική ψυχοθεραπεία`, `ψυχοθεραπεία`]}
      />
      <StaticQuery
        query={graphql`
          query {
            placeholderImage: file(relativePath: { eq: "omadiki.jpg" }) {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        `}
        render={(data) => (
          <div style={{ position: "relative" }}>
            {/* <Img
              fluid={data.placeholderImage.childImageSharp.fluid}
              style={{ height: "400px" }}
            /> */}

            <BackgroundImage
              Tag="section"
              style={{ height: "100vh", backgroundSize: "cover" }}
              fluid={data.placeholderImage.childImageSharp.fluid}
              backgroundColor={`#040e18`}
            ></BackgroundImage>
            <HeroText
              title="Ομαδική Ψυχοθεραπεία"
              styles={{ textAlign: "center" }}
            />
            <div
              style={{
                height: "100%",
                width: "100%",
                background: "rgba(0,0,0,0.3)",
                position: "absolute",
                top: "0px",
              }}
            ></div>
          </div>
        )}
      />

      <div
        className="container main-content"
        style={{ marginTop: "52px", zIndex: "100", position: "relative" }}
      >
        <p>
          Η ομαδική θεραπεία, αναφέρεται στην θεραπεία που γίνεται με τον
          ψυχοθεραπευτή και έναν αριθμό από θεραπευόμενους. Ο αριθμός αυτός
          συνήθως κυμαίνεται στα 7- 8 άτομα και δεν ξεπερνάει τα 12. Ο
          θεραπευτής επιλέγει ποια άτομα και με ποια κριτήρια αυτά θα ενταχθούν
          στην ομάδα, αφού πρώτα έχουν προηγηθεί μια σειρά από ατομικές
          συνεδρίες. Η συνοχή της ομάδας είναι πολύ σημαντική για τη θεραπεία
          και για αυτό το λόγο, ανάμεσα στα κριτήρια επιλογής είναι και οι
          κοινοί θεραπευτικοί στόχοι. Η διάρκεια της ομαδικής θεραπείας είναι
          μια ώρα και τριάντα λεπτά, μια φορά την εβδομάδα. Καμιά φορά οι
          θεραπευτές μπορεί να είναι και δύο.
        </p>
        <p>
          Η ομαδική θεραπεία είναι μια ιδιαίτερα αποτελεσματική μορφή
          ψυχοθεραπείας. Η ίδια η λειτουργία της ομάδας έχει δύναμη. Η προσωπική
          αλλαγή και η κατανόηση του εαυτού επέρχεται πιο γρήγορα σε σχέση με
          άλλες μορφές θεραπείας. Αυτό συμβαίνει γιατί η ομάδα, αποτελεί ένα
          ασφαλές πλαίσιο το οποίο όμως λειτουργεί σαν μικροκοινωνία. Δηλαδή, το
          άτομο επειδή σχετίζεται στην ομάδα όπως σχετίζεται και κοινωνικά στη
          ζωή, έχει τώρα τη δυνατότητα μέσω των αλληλοεπιδράσεων με τα άλλα
          μέλη, να παρατηρήσει τον εαυτό του, να πάρει πληροφορίες για το πως
          σχετίζεται και να εντοπίσει δυσλειτουργικές συμπεριφορές. Η ομάδα,
          έχει υποστηρικτικό χαρακτήρα και βοηθά το άτομο μέσω της διορθωτικής
          εμπειρίας να εξελίσσει και να καλυτερέψει τις διαπροσωπικές του
          σχέσεις.
        </p>
        <p>
          Άλλα οφέλη της ομαδικής θεραπείας είναι ότι το άτομο ανακαλύπτει ότι
          δεν είναι μοναδικό ως προς τα συναισθήματα, τις σκέψεις και τις
          δυσκολίες που βιώνει. Ο Irvin Yalom, γνωστός για το έργο του στην
          ομαδική ψυχοθεραπεία, στο βιβλίο του Θεωρία και Πράξη της Ομαδικής
          Ψυχοθεραπείας, αναφέρει ότι επιπρόσθετα οφέλη της ομάδας είναι:
        </p>
        <p>
          <ul>
            <li>
              Ενστάλαξη ελπίδας: η θεραπευτική πρόοδος των άλλων μελών
              δημιουργεί ελπίδα και αισιοδοξία στα άλλα μέλη.
            </li>
            <li>
              Μετάδοση πληροφοριών: τα μέλη αντλούν βοήθεια με το μοίρασμα
              πληροφοριών και συμβουλών.
            </li>
            <li>
              Μιμητική συμπεριφορά: τα μέλη ωφελούνται από τη μίμηση της
              συμπεριφοράς άλλων μελών της ομάδας.
            </li>
            <li>
              Υπαρξιακοί παράγοντες: βασικά ζητήματα της ανθρώπινης ύπαρξης όπως
              ο θάνατος και η μοναξιά συζητούνται. Τα μέλη καλούνται να δουν τη
              ζωή πιο ειλικρινά.
            </li>
            <li>
              Συνεκτικότητα της ομάδας: τα μέλη νιώθουν ότι ανήκουν σε ένα
              σύνολο και τα αποδέχονται για αυτό που είναι.
            </li>
            <li>
              Αλτρουισμός: τα μέλη ενισχύουν την αυτοεκτίμησή τους και την
              εμπιστοσύνη στον εαυτό τους καθώς προσφέρουν βοήθεια στα άλλα μέλη
              της ομάδας.
            </li>
          </ul>
        </p>
      </div>
    </Layout>
  );
};

export default Index;
